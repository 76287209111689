import React, { Component } from 'react'
import './RV_LeftColumn.css';

class RV_LeftColumn extends Component {
    constructor(props) {
      super(props);
      this.state = {
      };
    }  


    render() {
        return(
            <div className='RV_LCContainer'>
                    RV_LeftColumn
            </div>
        );
    }
}

export default RV_LeftColumn;