import React, { Component } from 'react'
import './RV_MainArea.css';

class RV_MainArea extends Component {
    constructor(props) {
      super(props);
      this.state = {
      };
    }  


    render() {
        return(
            <div className='RV_MAContainer'>
                    RV_MainArea
            </div>
        );
    }
}

export default RV_MainArea;