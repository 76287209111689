import React, { Component } from 'react'
import './RV_UpperBar.css';
import mainLogo from'../../Resources/EdgehealthLogoLinFULL.png';


class RV_UpperBar extends Component {
    constructor(props) {
      super(props);
      this.state = {
      };
    }  

    testlog = (received) =>{
        console.log('Received: '+received);
    }

    render() {
        return(
            <div className='RV_UBContainer'>
                   <img  src={mainLogo} style={{'width':'184px'}} alt=""/>
                   <div className='RV_UBRightContainer'>
                       <div className='RV_UBLabel' onClick={() => this.testlog('A test')}>Consola</div>
                       <div className='RV_UBLabel'>Perfil</div>
                       <div className='RV_UBLabel'>Salir</div>
                    </div>
            </div>
        );
    }
}

export default RV_UpperBar;