import React from "react";
import { BrowserRouter, Route, Routes, Link } from 'react-router-dom';
import './App.css';
import RV_UpperBar from './Components/RV_UpperBar/RV_UpperBar.jsx'
import RV_MainArea from './Components/RV_MainArea/RV_MainArea.jsx'
import RV_LeftColumn from './Components/RV_LeftColumn/RV_LeftColumn.jsx'


function App() {
  return (
    <div className="App">
      <header className="">
        <RV_UpperBar/>
        <div className='RV_MainContainer'>
  
            <RV_LeftColumn/>
            <RV_MainArea/>
           
        </div>
      </header>
    </div>
  );
}

export default App;
